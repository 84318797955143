
.grecaptcha-badge {
  visibility: hidden;
}

.recaptcha-notice {
  text-align: left;
  color: #DDD;
  font-size: 12px;
  margin-top: 20px;
}
