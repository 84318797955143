@import '~react-responsive-carousel/lib/styles/carousel.min.css';

.carousel .control-dots {
  li.dot {
    background-color: $blue;
    $size: 12px;
    width: $size;
    height: $size;

    &.selected {
      background-color: $orange;
    }
  }
}

.carousel-root {
  margin-bottom: -20px;
}

.slider-wrapper {
  padding-bottom: 50px;

  img {
    display: block;
  }
}
