$linkSize: 19px;
.navbar {
  @include media-breakpoint-up(md) {
    margin-bottom: 80px;
  }

  a:hover {
    text-decoration: none;
  }

  .nav-item {
    a {
      color: #FFF;

      h1 {
        font-size: 42px;
        font-weight: 700;
      }

      &.nav-link {
        font-size: $linkSize;
      }
    }
  }

  .dropdown-toggle.btn {
    color: #FFF;
    font-size: $linkSize;
  }
}

.navbar-brand {
  &, a {
    color: #FFF;
  }
}
