#password-strength {
  .strength-container {
    max-width: 450px;
    margin: 0 auto 100px;
  }
}

.p-strength {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  position: relative;

  input {
    font-size: 25px;
    padding: 15px;
  }

  .result {
    font-size: 25px;
    margin-top: 30px;
    min-height: 230px;
    width: 400px;
  }

  .online-attack {
    font-size: 21px;
  }

  .offline-attack {
    font-size: 15px;
  }
}

.ps-strength {
  margin-bottom: 20px;
}
