
$octave-width: 274px;
$octave-height: 512px;
$ratio: .6;

.octave {
  @include noUserSelect();
  min-height: $octave-height;
  position: absolute;
  display: flex;
  align-items: center;
  z-index: 1;
  left: -$octave-width * $ratio;
  top: 0;

  > div {
    transform-origin: top left;
    transform: scale($ratio) rotate(17deg);
  }
}

.octave-img {
  width: 274px;
}

.eye {
  position: absolute;
  opacity: 1;
  transition: opacity .2s ease-in-out;
}

.e-left {
  width: 35px;
  top: 84px;
  left: 144px;
}

.e-right {
  width: 34px;
  top: 97px;
  left: 230px;
}

.starfish {
  position: absolute;
  opacity: 0;
  transition: opacity .5s ease-in-out;
}

.password-focus {
  .starfish {
    opacity: 1;
  }

  .eye {
    opacity: 0;
  }
}

.sf-left {
  width: 105px;
  top: 56px;
  left: 95px;
}

.sf-right {
  width: 86px;
  top: 74px;
  left: 192px;
}

.login-form, .registration-form {
  position: relative;
  padding: 15px 0;
  z-index: 1;

  a {
    color: $danger;
  }
}
