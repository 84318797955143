$body-bg: #FFF;

$blue:    #50b9e3 !default;
$indigo:  #5534ff !default;
$purple:  #6f42c1 !default;
$pink:    #ff4863 !default;
$red:     #FF4863 !default;
$orange:  #f5a623 !default;
$yellow:  #ffc107 !default;
$green:   #28a745 !default;
$teal:    #20c997 !default;
$cyan:    #2d90e8 !default;

$border-radius:               .50rem !default;
$border-radius-lg:            .3rem !default;
$border-radius-sm:            .2rem !default;

$input-bg: transparent;
$input-color: white;
$input-placeholder-color: white;

$component-active-bg: $blue;
$custom-control-indicator-bg-size: 60% 60% ;
$custom-control-indicator-size: 1.8rem;

$input-disabled-bg: none;
$custom-control-label-disabled-color: inherit;
