
body {
  font-family: 'Lato', sans-serif;
  color: $white;
}

html, body {
  max-width: 100%;
  overflow-x: hidden;
}

.hook {
  font-size: 33px;
  line-height: 1.3;
  margin-bottom: 40px;

  @include media-breakpoint-down(sm) {
    font-size: 23px;
  }
}

.hook-center {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

section {
  position: relative;
  width: 100%;

  .container {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  &.first {
    .container {
      padding-top: 0;
      padding-bottom: 50px;
      @include media-breakpoint-down(sm) {
        padding-bottom: 15px;
      }
    }
  }
}

.push-sm-bottom {
  margin-bottom: 30px;
}

.bg {
  position: relative;
  z-index: 0;
  width: 100%;
  height: 100%;
}

.backscene {
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
  z-index: -100;
  display: flex;
  overflow-x: visible;

  .container {
    position: relative;
  }

  img {
    position: absolute;
  }
}

// MAIN CTA
@include media-breakpoint-down(sm) {
  .navbar::after {
    content: '';
    width: 100%;
  }
}

#main-cta {
  flex: 1 1;
  float: right;
  text-align: right;

  @include media-breakpoint-down(sm) {
    order: 1;
    text-align: center;
  }

  .btn {
    font-size: 20px;
  }
}

.nobr {
  white-space: nowrap;
}

#intro {
  .bg {
    z-index: 1;
    $opacity: 0.93;
    background-image: linear-gradient(to left, rgba(#507de8, $opacity), rgba(#402bb1, $opacity) 50%, rgba(#482fc5, $opacity));
  }

  .slider {
    max-width: 550px;
  }
}

#main-octave {
  @include media-breakpoint-down(sm) {
    width: 30%;
  }
  @include media-breakpoint-up(lg) {
    width: 80%;
  }
}

.navbar-brand {
  font-size: 42px;
  font-weight: 700;
}

.chat {
  max-width: 550px;

  > .row {
    flex-flow: nowrap;
    margin-bottom: 10px;
  }

  .date {
    overflow: hidden;
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;

    &:before,
    &:after {
      background-color: #FFF;
      content: "";
      display: inline-block;
      height: 1px;
      position: relative;
      vertical-align: middle;
      width: 50%;
    }

    &:before {
      right: 0.5em;
      margin-left: -50%;
    }

    &:after {
      left: 0.5em;
      margin-right: -50%;
    }
  }

}

.bubble, .author {
  margin-right: 15px;
  margin-left: 15px;
  flex: 1 1 auto;
}

.author {
  max-width: 37px;
  margin-top: 9px;
  flex: 0 0 auto;

  img {
    border-radius: 50%;
  }
}

.bubble {
  font-size: 19px;
  width: auto;
  flex: 0 1 auto;
  border-radius: 12px;
  margin-left: 0;
  padding: 12px;
  background: rgba($blue, 0.7);

  @include media-breakpoint-down(sm) {
    font-size: 17px;
  }
}

.flex-row-reverse {
  .bubble {
    margin-right: 0;
    margin-left: 15px;
  }
}

.highlight {
  color: #edf337;
}

.b-plain {
  background: #FFF;
  color: #000;

  .highlight {
    color: $blue;
  }
}

.chat-center {
  margin: 0 auto;
}

.uploaded-file {
  max-width: 150px;
  text-align: center;
  display: inline-block;

  img {
    margin: 0 auto;
    max-width: 50px;
  }

  .f-filename {
    font-size: 13px;
    line-height: normal;
  }
}

#pro {
  .bg {
    z-index: 1;
    $opacity: 0.77;
    background-image: linear-gradient(248deg, rgba(#51f5b6, 1), rgba(#2fb8ac, $opacity) 60%, rgba(#046c9f, $opacity));
  }
}

#submarine {
  padding: 0 100px;
  margin: 0 auto -180px;
  text-align: center;
  z-index: 2;


  @include media-breakpoint-down(sm) {
    margin-bottom: -110px;
  }
}

#demo {
  .bg {
    $opacity: 0.77;
    background-image: linear-gradient(to bottom, rgba(#507de8, $opacity), rgba(#543ae0, $opacity) 97%, rgba(#482fc5, $opacity));
  }
}

#digicode {
  .bg {
    $opacity: 0.77;
    background-image: linear-gradient(327deg, rgba(#fceaaa, $opacity), rgba(#fabd77, $opacity) 41%, rgba(#f86715, $opacity));

    .container {
      padding-bottom: 0;
    }
  }

  .hook {
    margin-left: auto;
    margin-right: auto;
    max-width: 600px;
  }

  .iphone {
    opacity: 0.1;

    img {
      display: block;
      margin: 50px auto 0;
    }
  }
}

#poll {
  .bg {
    $opacity: 0.77;
    background-image: linear-gradient(240deg, rgba(#51F5B6, $opacity) 0%, rgba(#2FB8AC, $opacity) 60%, rgba(#046c9f, $opacity));
  }
}

#octave-footer {
  width: 240px;
  height: 300px;
  background: url("../images/octave.png") top center no-repeat;
  background-size: 100%;
  margin-bottom: -100px;
  float: right;
}

#stores {
  display: flex;
  justify-content: flex-end;

  @include media-breakpoint-down(xs) {
    justify-content: center;
  }

  img {
    display: block;
    flex-grow: 1;
    max-height: 80px;
    margin: 2px;
  }
}

#footer {
  $opacity: 1;

  .container {
    padding-bottom: 0;
  }

  .bg {
    $opacity: 0.77;
    background-image: linear-gradient(to top, rgba(#507DE8, $opacity) 0%, rgba(#543AE0, $opacity) 97%, rgba(#482FC5, $opacity) 100%);
  }

  footer {
    margin-top: 80px;
    overflow: hidden;

    .col-sm {
      padding-bottom: 40px;
      align-self: flex-end;

      @include media-breakpoint-down(sm) {
        #octave-footer {
          zoom: 70%;
        }
      }
      @include media-breakpoint-down(xs) {
        text-align: center !important;

        #octave-footer {
          float: none;
          margin: 0 auto -380px;
          opacity: 0.5;
        }
      }
    }
  }
}

.brands {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;

  > div {
    padding: 0 15px;

    @include media-breakpoint-down(sm) {
      padding: 15px 0;
    }
  }
}

.form-100 {
  margin: 50px auto;
  max-width: 350px;
  text-align: center;

  .error {
    font-size: 20px;
    margin-bottom: 15px;
  }

  .form-control {
    padding: 27px;
  }

  button {
    width: 100%;
    padding: 15px;
    text-transform: uppercase;
  }
}

$offsets: (
                (zip, -50px, -17deg),
                (txt, 41px, -12deg),
                (doc, 89px, -4deg),
                (pdf, 130px, 0deg),
                (xls, 126px, 2deg),
                (jpg, 70px, 3deg),
                (png, 15px, 7deg)
);

.files {
  margin-top: -60px;
  margin-bottom: 50px;

  @include media-breakpoint-down(md) {
    margin-top: -100px;
    zoom: 70%;
  }
  @include media-breakpoint-down(sm) {
    margin-top: -150px;
    zoom: 40%;
  }
  @include media-breakpoint-down(xs) {
    margin-top: -240px;
    zoom: 30%;
  }

  > div {
    padding: 5px;
    flex-grow: 0;
  }

  @each $name, $offset, $rot, $margin in $offsets {
    .#{$name} {
      margin-top: -$offset;
      transform: rotateZ($rot);
    }
  }
}

.form-success {
  text-align: center;
  font-size: 24px;
  margin: 50px 0;

  svg {
    zoom: 130%;
  }
}

.GDPR-img {
  margin: 40px auto 20px;
  text-align: center;
  max-width: 150px;
}

.footer-links {
  list-style: none;

  a, .dropdown-toggle {
    color: #FFF;
  }
}
