
@mixin anim($way, $translate) {
  /* The animation code */
  @keyframes chat-bubble-#{$way} {
    0% {
      opacity: 0;
      transform: translateX($translate) scale(0,0);
    }
    100% {
      opacity: 1;
      transform: translateX(0)  scale(1, 1);
      zoom: 1;
    }
  }
}

@keyframes chat-row {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@include anim(left, -100px);
@include anim(right, 100px);

$speed: 0.2s;
$main-delay: 0s;
$latency-delay: 0.3s;

.chat {
  > .row {
    opacity: 0;
    transform: translateY(-50px);
  }
}

.chat-active {
  > .row {
    animation-name: chat-row;
    animation-duration: $speed;
    animation-fill-mode: forwards;

    @for $i from 1 through 15 {
      &:nth-of-type(#{$i}) {
        animation-delay: ($latency-delay + $speed) * ($i - 1) + $main-delay;
      }

      &:nth-of-type(#{$i}) .bubble {
        animation-delay: ($speed + $latency-delay) * ($i - 1) + .3s + $main-delay;
      }
    }
  }

  .bubble {
    text-align: left;
    animation-name: chat-bubble-left;
    animation-duration: .8s;
    animation-timing-function: cubic-bezier(.31,.86,.44,.89);
    animation-fill-mode: forwards;
  }

  .b-plain {
    animation-name: chat-bubble-right;
  }
}

.bubble {
  opacity: 0;
  transform: translateX(-100px) scale(0,0);
}

.b-plain {
  opacity: 0;
  transform: translateX(100px) scale(0,0);
}
