
@mixin svgFill($color) {
  svg:not(.no-fill) {
    path, circle, polygon {
      &:not(.no-fill) {
        fill: $color;
      }

      &.fill-stroke {
        stroke: $color;
      }
    }
  }
}

@mixin noUserSelect($select: none) {
  -webkit-touch-callout: $select; /* iOS Safari */
  -webkit-user-select: $select; /* Safari */
  -khtml-user-select: $select; /* Konqueror HTML */
  -moz-user-select: $select; /* Old versions of Firefox */
  -ms-user-select: $select; /* Internet Explorer/Edge */
  user-select: $select;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}
